import { DynamicKeys } from '@/client/types'
import { tokens } from '../theme'
const { font, breakpoint } = tokens

export const getSize: DynamicKeys = {
  xxs: `
    font-size: ${font.size.mini};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.mini};
    }
  `,

  baseroot: `
    font-size: ${font.size.xxsmall};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.baseroot};
    }
  `,
  xs: `
    font-size: ${font.size.xxsmall};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.xsmall};
    }
  `,
  sm: `
    font-size: ${font.size.xsmall};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.small};
    }
  `,
  md: `
    font-size: ${font.size.small};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.root};
    }
  `,
  lg: `
    font-size: ${font.size.root};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.h6};
    }
  `,
  xlg: `
    font-size: ${font.size.h6};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.h5};
    }
  `,
  xxlg: `
    font-size: ${font.size.h5};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.h4};
    }
  `,
  h3: `
    font-size: ${font.size.h4};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.h3};
    }
  `,
  h2: `
    font-size: ${font.size.h3};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.h2};
    }
  `,
  h1: `
    font-size: ${font.size.h2};

    @media (min-width: ${breakpoint.lg}) {
      font-size: ${font.size.h1};
    }
  `
}

export const getWeight: DynamicKeys = {
  normal: `
    font-weight: ${font.weight.normal};
  `,
  medium: `
    font-weight: ${font.weight.medium};
  `,
  bold: `
    font-weight: ${font.weight.bold};
  `,
  semiBlack: `
    font-weight: ${font.weight.semiBlack};
  `,
  black: `
    font-weight: ${font.weight.black};
  `
}

export const getTransform: DynamicKeys = {
  none: '',
  capitalize: `
    text-transform: capitalize;
  `,
  lowercase: `
    text-transform: lowercase;
  `,
  uppercase: `
    text-transform: uppercase;
  `
}
