// cel h 450px(sm)
// cel v 600px(md)
// tab h 768px(lg)
// tab v 992px(xl)
// small des 1296px(xl2)
// des 1344px(hd)

export type MediaProps = {
  [key in string]: number
}

const media: MediaProps = {
  xs: 0,
  sm: 28.125,
  md: 37.5, // 600
  lg: 48, // 768
  xl: 62, //992
  xl2: 74, //1184
  xl3: 75, // 1200
  xl4: 80, // 1280
  hd: 84 // 1344
}

export default media
