import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import { Row, Button, Icon } from '@/client/components'
import * as S from './styles'

import { MultiTableProps } from './types'
import { formatDate } from '@/client/utils/formatDate'
import { CloseModal } from '../../CookieConsent/styles'
import { useMultiChunkUpload } from '@/client/hooks/useMultiChunkUpload'
import { uploadChunkFiles } from '@/infra/services/uploadFile'
import { useMutation } from '@tanstack/react-query'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import router, { useRouter } from 'next/router'
import { boolean } from 'zod'
import { getTableContent } from '../helpers'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

export const Controls: FC<any> = memo(({ currentPage, goPrevious, goNext, totalPage, perPage, content }) => {
  return (
    <Row end='sm'>
      {currentPage > 1 && (
        <S.ActionButtons>
          <Button kind='dark' onClick={goPrevious}>
            <Icon name='arrow-left' />
            {getTableContent(content, 'prev_button', 'name')}
          </Button>
        </S.ActionButtons>
      )}
      {perPage !== 0 && currentPage * perPage < totalPage && (
        <S.ActionButtons>
          <Button kind='dark' onClick={goNext}>
            {getTableContent(content, 'next_button', 'name')}
            <Icon name='arrow-right' />
          </Button>
        </S.ActionButtons>
      )}
    </Row>
  )
})

Controls.displayName = 'Controls'
