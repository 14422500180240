import { ReactNode } from 'react'

// Constants for CMS
export const TABLE_CONTENT = 'table_content'
export const MAIN_TABLE = 'main_table'
export const COMMON_FIELDS = 'common_fields'
export const COMMON_FIELD_DATA = 'common_field_data'
export const COMMON_RICH_TEXT_EDITOR = 'common_rich_text_editors'
export const RICH_TEXT_EDITOR_FIELDS = 'rich_text_editor_fields'
export const BUTTON_GROUP = 'button_group'
export const ACTION_BUTTON = 'action_button'

// Constants for folders.
export const MY_ORDER_FILES = 'myorder-files'
export const GRPAHIC_TICKET_FILES = 'graphic-ticket-files'
export const DESIGN_SERVICE_FILES = 'design-service-files'
export const CUSTOM_FILES = 'custom-files'

// Upload modal constants
export const LABEL_WIDTH = 40
export const LABEL_HEIGHT = 40

export type MultiTableProps = {
  tableType: string
  page: number
  folderId?: string
  searchItem?: string
  folderName?: string
  content: any
}
