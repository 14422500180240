import React, { FC, memo } from 'react'
import * as S from './styles'
import { MultiTableProps } from './types'
import { CustomFiles, DesignServiceFiles, GraphicTicketFiles, OrderTable } from './TableStyle'
import { useRouterPush } from '@/client/hooks'
import { refineValueFromURL } from '@/client/utils/labelLibrary'

export const MultiTable: FC<MultiTableProps> = memo(({ tableType, page, searchItem, folderName, content }) => {
  const { asPath } = useRouterPush()
  const { getCurrentTab, searchParam } = refineValueFromURL(asPath)
  if (getCurrentTab === 'myorder-files') {
    return <OrderTable content={content} />
  }
  if (getCurrentTab === 'graphic-ticket-files') {
    return <GraphicTicketFiles searchItem={searchItem} content={content} />
  }
  if (getCurrentTab === 'design-service-files') {
    return <DesignServiceFiles searchItem={searchItem} content={content} />
  }
  if (getCurrentTab === 'custom-files') {
    return <CustomFiles searchItem={searchItem} content={content} />
  }

  return <></>
})

MultiTable.displayName = 'MultiTable'
