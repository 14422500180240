/**
 * Retrieves the content from cms.
 *
 * @param {object} content - CMS row data.
 * @param {number|string} id - The unique identifier for the table row.
 * @param {any} item - An object or value associated with the specific row.
 * @returns {string} cms entry for based on the ID.
 */

export const getTableContent = (content, id, item) => {
  const getData = content.filter((e) => e['id'] === id)
  return getData.length > 0 ? getData[0][item] : ''
}

/**
 * Generate URL string for Image preview.
 *
 * @param {string} url - CMS row data.
 * @param {string} pageShowPreviewParam - Page no.
 * @returns {string} generated URL.
 */
export const getImageLinkString = (url, pagshowPrevieweParam, id) => {
  const newUrl = url + `?page=${pagshowPrevieweParam === '' ? 1 : pagshowPrevieweParam}`
  return newUrl + '&preview=1&width=1024&height=1024'
}

export const getPageNo = (filterString) => {
  const getPage = filterString.split('page=')[1] || 0
  return Number(getPage)
}

const convertToDate = (dateString) => {
  /**
   * Static date
   * Today + 48hrs
   */
  const currentDate = new Date(dateString)
  const futureDate = new Date(currentDate.getTime() + 48 * 60 * 60 * 1000)
  const day = String(futureDate.getDate()).padStart(2, '0')
  const month = String(futureDate.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const year = futureDate.getFullYear()
  return `${day}.${month}.${year}`
}
