import styled, { keyframes } from 'styled-components'
import { Button } from '@/client/components/Button/Button'
import borders from '@/client/components/theme/borders'
import breakpoint from '@/client/components/theme/breakpoint'
import color from '@/client/components/theme/color'
import font from '@/client/components/theme/font'
import opacity from '@/client/components/theme/opacity'
import space from '@/client/components/theme/space'
import zindex from '@/client/components/theme/zindex'

export const MultitableWrap = styled.div`
  & .mutile-table-inner-row {
    &.mutile-table-outer-row-header {
      padding: 0.5rem 0;
    }
    &.mutile-table-inner-row-header {
      margin: 0;
      padding: 0.5rem 0;
      border-bottom: 1px solid ${color.grayscale.light};

      display: none;
      @media (min-width: ${breakpoint.xl2}) {
        display: flex;
      }
    }
  }

  & .multitable-row {
  }

  & .multitable-select-field {
    // max-width: 40px;
  }

  & .multiple-select-field-sorts {
    padding: 0.5rem 0;
    & .multitable-select-field {
      // max-width: none;
    }

    & .check-box-ms {
      justify-content: flex-end;
      & .shape-class {
        margin-right: 0;
      }
    }
  }
  & .lastorder-col {
    // max-width: none;
  }

  & .createdate-col {
    @media (min-width: ${breakpoint.xl2}) {
      // max-width: 110px;
    }
  }

  & .sorts-col {
    @media (min-width: ${breakpoint.xl2}) {
      // max-width: 87px;
    }
  }
  & .size-col {
    @media (min-width: ${breakpoint.xl2}) {
      // max-width: 100px;
    }
  }
`
export const TBlock = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
`
export const TBlockContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem;

  & > div {
    width: 100%;
  }
`
export const multiTableRow = styled.div<{ isRowChecked: boolean }>`
  & .mutile-table-inner-row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    ${(p) =>
      p.isRowChecked &&
      `background: ${color.grayscale.lightGrayBlue}; 
      border: 2px solid ${color.grayscale.blueGray};
      `}
  }
  & .arrow-checkbox {
    position: relative;
  }

  & .arrow-checkbox > .icon-chevron-down {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.5s ease;
    transform: ${(p) =>
      p.isRowChecked ? 'translate(-25%, -25%) rotate(0deg)' : 'translate(-25%, -25%) rotate(-90deg)'};
  }

  & .arrow-checkbox > .check-box-ms {
    opacity: 0;
  }
  & .arrow-checkbox > .check-box-ms .inner-shape-class {
    background: none;
    opacity: 0;
  }
  border-bottom: 1px solid ${color.grayscale.light};
  &:nth-child(odd) {
    background: ${color.grayscale.lightWhite};
  }

  & .lastorder-col {
    // max-width: none;
  }

  & .createdate-col {
    @media (min-width: ${breakpoint.hd}) {
      // max-width: 110px;
    }
  }

  & .sorts-col {
    @media (min-width: ${breakpoint.hd}) {
      // max-width: 80px;
    }
  }
  & .size-col {
    @media (min-width: ${breakpoint.hd}) {
      // max-width: 100px;
    }
  }
`

export const OptionLink = styled.div``
export const OptionData = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem 0;
`
export const OptionStyle = styled.div`
  display: flex;
  align-items: center;
`
export const ActionButtons = styled.div`
  padding: 1rem 0;
  margin: 0 0.5rem;
  & a {
    background: ${color.primary.dark};
    padding: 0.5rem 0.8rem;
    display: inline-block;
    border-radius: 0.5rem;
    color: ${color.grayscale.white};
    font-weight: ${font.family.text};
    font-size: ${font.size.baseroot};
  }
`
export const ActionBlock = styled.div`
  display: flex;
`
export const ActionIcon = styled.div`
  display: flex;
  padding-top: 0.5rem;
  padding-right: 1rem;
`
export const ActionInfo = styled.div`
  display: flex;
`
export const ActionBlockWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
`
export const ArtWorkServiceAssitance = styled.div`
  width: 100%;
  background: ${color.grayscale.lavender};
  padding: 0.5rem 1rem;
`

export const ArtWorkServiceAssitanceContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    margin: 0;
    padding: 0 1rem;
  }
`

export const FolderForm = styled.form``

export const UploadWrapper = styled.div``
export const UploadContent = styled.div``
export const UploadSuccessContent = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9;
  background: ${color.grayscale.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 55px;

  & > div {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const UploadControl = styled.div`
  display: flex;
  & button {
    margin-right: 1.5rem;
  }
`

export const UploadActionWrapper = styled.div`
  width: calc(100% - 400px);
`
export const UploadSettings = styled.div`
  width: 400px;
`
export const UploadHeader = styled.div<{ gradient?: boolean }>`
  width: 100%;
  height: auto;
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  color: ${color.grayscale.white};
  ${(prop) =>
    prop.gradient
      ? `
    background: rgb(0, 38, 62);
    background: linear-gradient(90deg, rgba(0, 38, 62, 1) 9%, rgba(39, 97, 182, 1) 100%);
    `
      : `background: rgb(0, 38, 62);`}
`

export const UFields = styled.div`
  margin-bottom: 2rem;
`
export const ModalCustom = styled.div`
  & .modal-custom {
    & .upload-modal > div {
      padding: 0;
    }

    & .modal-rel-header-cp {
      margin: 0;
    }
  }

  & .modal-rel-content-cp {
    max-width: 60rem;
  }

  & .modal-rel-inner-content {
    max-height: none;
  }

  & button[aria-label='Close'] {
    z-index: 9;
  }

  & button[aria-label='Close'] span {
    color: ${color.grayscale.white};
  }
`
export const Block = styled.div`
  font-size: ${font.size.small};
  color: ${color.primary.darker};
  font-family: ${font.family.text};
  font-weight: ${font.weight.medium};
  margin-bottom: 0.5rem;
`

export const UploadSettingsContainer = styled.div`
  padding: 1rem 1rem;
`
export const InlineInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > div {
    width: 100%;
    min-width: unset;
  }

  & .up-divider {
    margin: 0 0.5rem;
  }

  & .basic-input-element {
    height: calc(3.2rem - 0px);
  }

  & .basic-input-element {
    font-size: ${font.size.baseroot};
  }

  & .selected-option .arrow-small-set {
    right: 15px;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  margin: 1rem 0.5rem;

  & .ico-upload {
    span {
      font-size: 0.775rem;
    }
  }
`
export const UploadActionWrapperContainer = styled.div`
  padding: 1rem 1rem;
`
export const UploadedIems = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const UList = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid ${color.grayscale.darkSilver};
  border-bottom: 1px solid ${color.grayscale.darkSilver};
  opacity: ${(p) => (p.isDisabled ? '1' : '0.5')};
  flex-wrap: wrap;
  & p {
    margin: 0 0.5rem;
  }

  & a {
    display: inline-block;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  & a:hover {
    text-decoration: none;
  }

  & .ul-list-close {
    margin-right: 0.5rem;
  }
`

export const UListItem = styled.div`
  margin: 0 0.2rem;

  & p {
    margin: 0;
  }
`
export const UploadButton = styled.div`
  max-width: 130px;

  & input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  & label {
    position: relative;
    max-width: 130px;
    width: 100%;
    display: inline-block;
    margin-right: 1rem;
  }
  & span.label-upload-button {
    display: inline-block;
    background: ${color.warning.pure};
    padding: 1rem 1rem;
    border-radius: 0.5rem;

    & span {
      margin: 0 0.5rem;
    }
  }
`

export const ErrorInfo = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  width: 100%;
`

export const GreenDot = styled.div`
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 100px;
`
export const RedDot = styled.div`
  width: 8px;
  border-radius: 100px;
  height: 8px;
  background: red;
`

export const Details = styled.div<{ open?: boolean }>`
  display: block;
  height: auto;
  margin-bottom: ${space.sm};
  font-family: ${font.family.text};
  font-size: ${font.size.small};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding-top: 2rem;
  ul {
    padding-left: 0;

    li {
      margin-top: 2px;
      &:last-child {
        & .details-item {
          border-bottom: none;
        }
      }
    }
  }
`
export const DetailsItemProperty = styled.div`
  max-width: 210px;
  width: 100%;
  font-weight: ${font.weight.bold};
`
export const DetailsItemValue = styled.div``
export const DetailsItem = styled.div`
  display: flex;
  padding: 0.2rem 0;
  border-bottom: 1px solid ${color.grayscale.gray};
`

export const ImageBlock = styled.div`
  cursor: pointer;
  & .popup {
    max-width: 40px;
  }
`
export const WrapText = styled.div`
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
