export const refineValueFromURL = (urlValue) => {
  const clearURL = urlValue.replace('/my-account/label-library/', '')
  const searchParamString = clearURL.split('/?')
  // Current Tab [myorder-files, graphic-ticket-files, custom-files, ...]
  const getCurrentTab = clearURL.split('/').shift()
  // Folder name or number
  const getFolderName = searchParamString[0].replace(/\/$/, '').split('/').pop()
  const getQueryParams = new URLSearchParams(searchParamString[1])
  const getCurrentPage = Number(getQueryParams.get('page') || 1)
  const getJobId = Number(getQueryParams.get('job_id'))
  const searchParam = getQueryParams.get('search')
  return { getCurrentTab, getCurrentPage, getFolderName, getJobId, searchParam }
}

// JobId and file_id
export const refineReorderParam = (urlValue) => {
  const clearURL = urlValue.replace('/my-account/label-library/', '')
  const queryString = clearURL.split('/?')
  const queryParams = new URLSearchParams(queryString[1])
  const jobId = queryParams.get('job_id')
  const fileId = queryParams.get('file_id')
  const getSorts = queryParams.get('sort')
  return { jobId, fileId, getSorts }
}

// ****************************************
// ****************************************
// Job_id mostly for reorder functionality.
// ****************************************
// ****************************************

export const getJobIDFromURL = (urlValue) => {
  const jobId = urlValue ? urlValue.split('job_id=')[1] : undefined
  return { jobId }
}
