import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import { Row, Col, Breadcrumb, Checkbox, Input, Loader, Button } from '@/client/components'
import * as S from './styles'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { useClearQuery, useDebounce, useRouterPush, useSearchPages } from '@/client/hooks'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import router from 'next/router'

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const MultiTableSearch: FC<any> = memo(({ content }) => {
  const { routerPush, defaultLocale, pathname, query } = useRouterPush()
  const { mutate: clearQuery } = useClearQuery()
  const [searchParam, setSearchParam] = useState('')
  /**
   *
   * Context for Label Library
   *
   */
  const { setSearchKeyword, setJobId } = useContext(LabelLibraryContext)

  const submitSearch = (value) => {
    const id = router.query.id
    let newPathname = router.pathname
    if (id) {
      newPathname = newPathname.replace('[id]', `${id}`)
    }
    router.push(
      {
        pathname: newPathname,
        query: value !== '' ? { search: value } : {}
      },
      undefined,
      { shallow: true }
    )
  }
  const [isClickedOutside, setClickedOutside] = useState(false)
  return (
    <S.MultiTableSerchWrapper>
      <Row isFull>
        <Col noGutter>
          <S.Search role='button' onClick={() => setClickedOutside(false)}>
            <S.SearchInput $active={true}>
              <S.SearchForm
                onSubmit={(e) => {
                  e.preventDefault()
                  setJobId('')
                  submitSearch(searchParam)
                }}
              >
                <Input
                  isBlock
                  id='input-search'
                  name='p'
                  type='search'
                  placeholder={content['table_header']['search_bar']}
                  onChange={(e) => {
                    setSearchParam(e.currentTarget.value.toLocaleLowerCase())
                  }}
                  className='table-search'
                  autoComplete='off'
                  rightElement={
                    // <S.SearchIcon>{isFetching ? <Loader size='small' /> : <Icon name='search' size='s5' />}</S.SearchIcon>
                    <S.SearchIcon>
                      {
                        <Button
                          onClick={() => {
                            setJobId('')
                            submitSearch(searchParam)
                          }}
                          kind='blank'
                          size='small'
                        >
                          <Icon name='search' size='s5' />
                        </Button>
                      }
                    </S.SearchIcon>
                  }
                />
              </S.SearchForm>
            </S.SearchInput>
          </S.Search>
        </Col>
      </Row>
    </S.MultiTableSerchWrapper>
  )
})

MultiTableSearch.displayName = 'MultiTableSearch'
