import { Auth } from 'aws-amplify'
import { setCookie } from 'nookies'
import { Cookies, ICartCheckoutPayload, ICartPayload, IAddCartItemPayload, ICartSelection } from '@/client/types'
import { api, protectedApi } from '@/client/utils'
import {
  customFiles,
  designServiceFiles,
  graphicTicketFiles,
  myorderFiles,
  folderEntires
} from '@/infra/data/labelLibrary'
/**
 * Gets folder lists
 * @param cartId
 * @returns
 */
export const getProxyImage = async (imageUrl) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/customer/${attributes['custom:customer_id']}/${imageUrl}`
  const { data } = await protectedApi.get<any>(url, {
    headers: {
      Accept: 'image/*' // Optional: depending on your API's expected response type
    },
    responseType: 'blob'
  })
  return URL.createObjectURL(data)
}
