import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import {
  Row,
  Col,
  Breadcrumb,
  Checkbox,
  Button,
  Link,
  Modal,
  Input,
  Select2,
  Loader,
  ProgressLoader,
  ProxyImage
} from '@/client/components'
import * as S from './styles'
import { Icon } from '@/client/components/Icon'
import { Text } from '@/client/components/Text'
import { MultiTableProps } from './types'
import {
  useGetFolderEntries,
  useGetCustomTableData,
  useGetUserInfos,
  useRouterPush,
  useGetPreview,
  useGetImageProxy,
  useMutateDeleteFile,
  useClearQuery
} from '@/client/hooks'
import { formatDate } from '@/client/utils/formatDate'
import { CloseModal } from '../../CookieConsent/styles'
import { useMultiChunkUpload } from '@/client/hooks/useMultiChunkUpload'
import { uploadChunkFiles } from '@/infra/services/uploadFile'
import { useMutation } from '@tanstack/react-query'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import router, { useRouter } from 'next/router'
import { boolean } from 'zod'
import { Controls } from './Controls'
import Image from 'next/image'
import {
  ACTION_BUTTON,
  BUTTON_GROUP,
  COMMON_FIELD_DATA,
  COMMON_FIELDS,
  COMMON_RICH_TEXT_EDITOR,
  LABEL_HEIGHT,
  LABEL_WIDTH,
  MAIN_TABLE,
  RICH_TEXT_EDITOR_FIELDS,
  TABLE_CONTENT
} from '../types'
import { getImageLinkString, getTableContent } from '../helpers'
import { refineValueFromURL } from '@/client/utils'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const CustomFiles: FC<any> = memo(({ searchItem, content }) => {
  console.log('This i')
  const [eachItem, setEachItem] = useState<string[]>([])
  const [showDropdown, setShowDropdown] = useState(null)
  const [eachSortItem, setEachSortItem] = useState<any>([])
  const [labelWidth, setLabelWidth] = useState<number>(LABEL_WIDTH)
  const [labelHeight, setLabelHeight] = useState<number>(LABEL_HEIGHT)
  const { asPath } = useRouterPush()
  const { getCurrentPage, searchParam, getFolderName } = refineValueFromURL(asPath)

  /**
   * Dynamically accesses CMS.
   * The property names are stored in constants,
   * allowing for dynamic and flexible property access.
   *
   * @param {Object} content - The object containing the cms content data.
   * @param {string} CMS_ENTRY - Child elements of cms content.
   * @param {string} CMS_ENTRY - Child elements of cms content.
   * @returns {any} cms_content - The content.
   */
  const {
    [TABLE_CONTENT]: { [MAIN_TABLE]: table_Content },
    [COMMON_FIELDS]: { [COMMON_FIELD_DATA]: cms_content },
    [BUTTON_GROUP]: { [ACTION_BUTTON]: button_group_data }
  } = content

  const {
    [COMMON_RICH_TEXT_EDITOR]: { [RICH_TEXT_EDITOR_FIELDS]: cms_modal_content }
  } = content

  /**
   *
   * Context for Label Library
   *
   */
  const {
    addToCopySelection,
    currentFolder,
    currentPage,
    totalPage,
    setTotalPage,
    setLoader,
    setMessages,
    uploadModal,
    setUploadModal,
    setJobId,
    setPerPage,
    searchKeyword,
    perPage,
    setCurrentSort
  } = useContext(LabelLibraryContext)

  /**
   *
   *
   */

  /**
   *
   * Fetch Table data
   *
   */
  const {
    data: tableData,
    isLoading,
    refetch,
    isSuccess: customTableDataReady,
    status: customTableLoadingStatus
  } = useGetCustomTableData({
    filterString:
      searchParam == null ? `${getFolderName}?page=${getCurrentPage}` : `${getFolderName}?search=${searchParam}`
  })

  /**
   * Folder entries
   *
   */

  const { data: folderEntries } = useGetFolderEntries(uploadModal)

  const [refinedResults, setRefinedResults] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formLoader, setFormLoader] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState(0)
  const [tempFolderId, setTempFolderId] = useState(0)
  // Upload Folder setlector
  const [folderSelector, setFolderSelector] = useState([{ id: 1, name: '' }])

  const [buffer, setBuffer] = useState<any>([])
  const [currentProgress, setCurrentProgress] = useState<number[]>([])
  const { events } = useRouter()
  const [failedStatus, setFailedStatus] = useState(false)
  const [loadetShowStatus, setLoadetShowStatus] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [activePreview, setActivePreview] = useState<number | null>(null)
  const [subActivePreview, setSubActivePreview] = useState<number | null>(null)
  const { addFile, status, currentItem } = useMultiChunkUpload({
    queryFn: uploadChunkFiles,
    onFinally: ({ item, response, final, progress = 0 }) => {
      if (!item) {
        setFailedStatus(true)
        setLoader(false)
        return false
      }
      setFailedStatus(false)
      // setCurrentProgress(progress)
      setCurrentProgress((prevProgress) => {
        const newProgress: any = [...prevProgress] // Create a copy of the current state
        newProgress[item.fileUploadindex ? item.fileUploadindex : 0] = progress // Update the specific index
        return newProgress // Return the new array
      })

      refetch()
      setLoader(false)
    },
    onError: () => {
      console.log('or error')
      setFailedStatus(true)
      setLoader(false)
      return false
    },
    onAbort: () => {
      console.log('Upload aborted')
    }
  })

  const selectRow = (elemId, filename, job_id) => {
    setEachItem((prevItems) => [
      ...prevItems.slice(1), // Remove the first item
      tableData['subSorts'].find((e) => e['id'] === elemId) // Add the new item to the end
    ])
    addToCopySelection({
      filename: filename,
      customerFileId: elemId
    })
    setJobId(job_id)
  }

  const selectSubSortsRow = (elem, subSortId) => {
    setEachSortItem((prevItems) =>
      prevItems.map((item) => {
        if (item.id === elem.id) {
          const isPresent = item.subSorts.includes(subSortId)
          const updatedSubSorts = isPresent
            ? item.subSorts.filter((id) => id !== subSortId)
            : [...item.subSorts, subSortId]

          return {
            ...item,
            subSorts: updatedSubSorts
          }
        }
        return item
      })
    )
  }

  const isSubSortsChecked = (e, subSortId) => {
    const element = eachSortItem.find((el) => el.id === e)

    // Check if the element exists and if subSortId is in the subSorts array
    if (element) {
      return element.subSorts.includes(subSortId)
    }

    // Return false if no element is found with the matching id
    return false
  }

  const toggleMore = (i) => {
    showDropdown === i ? setShowDropdown(null) : setShowDropdown(i)
  }

  /*
   * Submit form.
   */
  const handleSubmitForm = async () => {
    setFormLoader(true)
    // await createFolder({ folderName: 'test-folder' })
    setUploadModal(false)
  }

  /*
   * Validate form.
   */
  const handleValidateForm = () => {
    // return formError
    return false
  }

  /*
   * Handle form change for folder creation.
   */
  const handleChangeForm = (newName) => {
    // setFolderName(newName.target.value)
    // zodParsing(newName.target.value)
  }

  const openReupload = () => {
    // setIsModalOpen(true)
    setUploadModal(true)
  }

  const changeFolder = (e) => {
    setTempFolderId(Number(e.target.value))
    setSelectedFolder(e.target.value)
  }

  const upload = async () => {
    try {
      setLoadetShowStatus(true)
      await addFile(buffer, {
        width: labelWidth,
        height: labelHeight,
        page: selectedFolder == 0 ? currentFolder : selectedFolder
      })
    } catch (err) {
      setLoadetShowStatus(false)
      setFailedStatus(true)
    }
  }

  // Once upload failed, do reupload.
  const reUpload = async (indexNo) => {
    try {
      setLoadetShowStatus(true)
      setFailedStatus(false)
      await addFile([buffer[indexNo]], {
        width: labelWidth,
        height: labelHeight,
        page: selectedFolder == 0 ? currentFolder : selectedFolder
      })
    } catch (err) {
      setLoadetShowStatus(false)
      setFailedStatus(true)
    }
  }

  const reuploadAction = () => {
    setCurrentProgress([])
    setBuffer([])
    setSuccessMessage(false)
  }

  // Set/Update folder contents here (for upload).
  const setFolderContents = () => {
    const folderSelectorMutation = folderEntries && [
      ...folderEntries.map((e) => {
        return {
          name: e['folder_name'],
          id: e['id']
        }
      })
    ]
    setFolderSelector(folderSelectorMutation)
  }

  useEffect(() => {
    setFolderContents()
  }, [folderEntries])

  useEffect(() => {
    if (tableData && tableData.data.length == 0) {
      setMessages('Sorry, There is no data associated with this folder')
    } else {
      setMessages('')
    }
    if (customTableDataReady && tableData) {
      setCurrentSort([0])
      setTotalPage(tableData['total'])
      setPerPage(tableData['per_page'])
      setLoader(false)
    }
  }, [tableData, customTableDataReady, customTableLoadingStatus, asPath])

  useEffect(() => {
    console.log(currentProgress, 'currentProgress')
    if (
      currentProgress.length !== 0 &&
      currentProgress.length === buffer.length &&
      currentProgress.every((num) => num === 100)
    ) {
      setTimeout(() => {
        setSuccessMessage(true)
      }, 1000)
    }
  }, [currentProgress])

  const processUploadFiles = async (files) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      const fileData = {
        file,
        progress: 0,
        fileUploadindex: index // Add the index here
      }
      setBuffer((prev) => [...prev, fileData])
      setCurrentProgress((prev) => [...prev, 0])
    }
  }

  const removeFromBuffer = (index) => {
    setFailedStatus(false)
    const tempBuffer = buffer.filter((_, idx) => idx !== index)
    const tempCurrentProgress = currentProgress.filter((_, idx) => idx !== index)
    console.log(tempBuffer)
    setBuffer(tempBuffer)
    setCurrentProgress(tempCurrentProgress)
    setSuccessMessage(false)
  }

  const goNext = () => {
    const getNextPage = totalPage === getCurrentPage ? getCurrentPage : getCurrentPage + 1
    const refinedPath = asPath.split('?')[0]
    router.push(
      {
        pathname: refinedPath,
        query: { page: getNextPage }
      },
      undefined,
      { shallow: true }
    )
  }

  const goPrevious = () => {
    const previousPage = getCurrentPage === 1 ? getCurrentPage : getCurrentPage - 1
    const refinedPath = asPath.split('?')[0]
    router.push(
      {
        pathname: refinedPath,
        query: { page: previousPage }
      },
      undefined,
      { shallow: true }
    )
  }

  const redirectTo = (pathName) => {
    router.push(pathName)
  }

  const getModalContent = (id, item) => {
    const getData = content['modal_group']['modal'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }
  const getInputFieldContent = (id, item) => {
    const getData = content['input_field'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }
  const getButtonContent = (id, item) => {
    const getData = content['button_group']['action_button'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }

  // Generate image link based on page
  const getImageLink = (url, pagshowPrevieweParam, id) => {
    const newUrl = `customerFile/${id}?` + `&page=${pagshowPrevieweParam === '' ? 1 : pagshowPrevieweParam}`
    return newUrl + '&width=1024&height=1024'
  }

  // Show preivew for parent elements
  const showPreview = (index: number) => {
    if (activePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setSubActivePreview(null)
      setActivePreview(index)
    }
  }

  const closeUploadModal = () => {
    setBuffer([])
    setCurrentProgress([])
  }
  // Show preivew for sub elements
  const showSubPreview = (index: number) => {
    if (subActivePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setActivePreview(null)
      setSubActivePreview(index)
    }
  }

  // Handles outside click to hide preview components
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null

      if (activePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
      if (subActivePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
    }

    // Adding event listener to the document
    document.addEventListener('click', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [activePreview, subActivePreview])

  const backToFolder = (e, url) => {
    e.preventDefault()
    setUploadModal(false)
    router.push(
      {
        pathname: url
      },
      undefined,
      { shallow: true }
    )
  }

  return (
    <S.MultitableWrap>
      <>
        <Row className='multitable-row' middle='xs' isFull>
          <Col xs='12' className='mutile-table-outer-row-header' noGutter>
            <Row isFull className='mutile-table-inner-row mutile-table-inner-row-header' middle='xs'>
              <Col xs='5' xl2='5' noGutter>
                <Row isFull middle='xs'>
                  <Col xs='1' xl2='1' className='multitable-select-field'></Col>
                  <Col xs='2' xl2='2'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent(table_Content, 'preview', 'table_cell_name')}
                    </Text>
                  </Col>
                  <Col xs='9' xl2='9'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent(table_Content, 'filename', 'table_cell_name')}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col xs='2' xl2='1' className='sorts-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(table_Content, 'pages', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' xl2='1' className='size-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(table_Content, 'size', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='1' xl2='2' className='createdate-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(table_Content, 'create_date', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='1' xl2='2' className='createdate-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(table_Content, 'last_order_no', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='1' xl2='1' className='lastorder-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(table_Content, 'preflight', 'table_cell_name')}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
      {tableData?.filteredResults.map((elm, i) => (
        <S.multiTableRow
          key={`table-data-custom-${i}`}
          isRowChecked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
        >
          <Row className='multitable-row' middle='xs' isFull>
            <Col xs='12' noGutter>
              <Row isFull className='mutile-table-inner-row' middle='xs'>
                <Col xs='5' xl2='5' noGutter>
                  <Row isFull middle='xs'>
                    <Col xs='1' xl2='1' className='arrow-checkbox'>
                      <Icon name='chevron-down' size='s3' />
                      <Checkbox
                        key={elm['id']}
                        id={elm['id']}
                        value={elm['id']}
                        name={elm['id']}
                        label={''}
                        description={''}
                        checked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
                        onChange={() => selectRow(elm['id'], elm['filename'], elm['job_id'])}
                        kind='circle'
                      />
                    </Col>
                    <Col xs='2' xl2='2' style={{ position: 'relative' }}>
                      {' '}
                      <S.ImageBlock>
                        {elm['preview_link'] || elm['preview_link'] !== '' ? (
                          <ProxyImage
                            src={getImageLinkString(
                              `customerProjectFolder/${currentFolder}/customerFile/${elm['id']}`,
                              '',
                              elm['id']
                            )}
                            page={currentPage}
                            width={127}
                            height={92}
                            alt={'Preview image'}
                            title={'Preview Image'}
                            index={i}
                            showPreview={showPreview}
                            isOpen={activePreview === i}
                          />
                        ) : (
                          <img src='/img/my-account/no-image.png' alt='Preview no image' style={{ maxWidth: '100%' }} />
                        )}{' '}
                      </S.ImageBlock>
                    </Col>
                    <Col xs='9' xl2='9'>
                      <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                        {elm['filename']}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                <Col xs='2' xl2='1' className='sorts-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['number_of_pages']}
                  </Text>
                </Col>
                <Col xs='2' xl2='1' className='size-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['width']} X {elm['height']}
                  </Text>
                </Col>
                <Col xs='1' xl2='2' className='createdate-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {formatDate(elm['created_at'])}
                  </Text>
                </Col>
                <Col xs='1' xl2='2' className='lastorder-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['last_order_number']}
                  </Text>
                </Col>
                <Col xs='1' xl2='1' className='preflight'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['preflight_task_status'] === 'success' ? <S.GreenDot></S.GreenDot> : <S.RedDot></S.RedDot>}
                  </Text>
                </Col>
              </Row>
              {eachItem.length > 0 &&
                eachItem
                  .filter((e) => e['id'] === elm['id'])
                  .map((subSort, subSortIndex) => (
                    <>
                      {Array.from({ length: subSort['subSorts'] }, (_, index) => (
                        <Row isFull middle='xs' className='multiple-select-field-sorts' key={index + 'sub'}>
                          <Col xs='5' xl2='5'>
                            <Row isFull middle='xs'>
                              <Col xs='2' noGutter>
                                <Checkbox
                                  key={subSort['id']}
                                  id={subSort['id']}
                                  value={subSort['id']}
                                  name={subSort['id']}
                                  label={''}
                                  description={''}
                                  onChange={() => selectSubSortsRow(elm, subSort['id'])}
                                  kind='circle'
                                  checked={true}
                                />
                              </Col>
                              <Col xs='2' xl2='3' style={{ position: 'relative' }}>
                                <S.ImageBlock>
                                  <ProxyImage
                                    src={getImageLinkString(
                                      `customerProjectFolder/${currentFolder}/customerFile/${elm['id']}`,
                                      index + 1,
                                      elm['id']
                                    )}
                                    page={currentPage}
                                    width={127}
                                    height={92}
                                    alt={'Preview image'}
                                    title={'Preview Image'}
                                    index={index}
                                    showPreview={showSubPreview}
                                    isOpen={subActivePreview === index}
                                  />
                                </S.ImageBlock>
                              </Col>
                              <Col xs='8' xl2='7'>
                                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                                  {elm['filename']}
                                </Text>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs='2' xl2='1' className='sorts-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {index + 1}/{elm['number_of_pages']}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='1' className='size-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['width']} X {elm['height']}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='createdate-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {formatDate(elm['created_at'])}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='lastorder-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['last_order_number']}
                            </Text>
                          </Col>
                        </Row>
                      ))}

                      {!elm['preflight_task_status'] && (
                        <>
                          <S.ActionBlockWrapper>
                            <S.ActionBlock>
                              <S.ActionIcon>
                                <Icon name='x' size='s4' />
                              </S.ActionIcon>
                              <S.ActionInfo>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: getTableContent(cms_modal_content, 'preflight', 'label')
                                  }}
                                />
                              </S.ActionInfo>
                            </S.ActionBlock>
                            <Button kind='warning' onClick={openReupload}>
                              {getTableContent(cms_content, 're_upload', 'label')}
                            </Button>
                          </S.ActionBlockWrapper>

                          <S.ArtWorkServiceAssitance>
                            <S.ArtWorkServiceAssitanceContent>
                              <Icon name='group-set' size='s6' /> <p>{getTableContent(cms_content, 'hint', 'label')}</p>
                              <Button kind='warning' onClick={() => redirectTo('/servicios-de-revision/')}>
                                {getTableContent(cms_content, 'order_artwork_service', 'label')}
                              </Button>
                            </S.ArtWorkServiceAssitanceContent>
                          </S.ArtWorkServiceAssitance>
                        </>
                      )}
                    </>
                  ))}
            </Col>
          </Row>
        </S.multiTableRow>
      ))}
      <Controls
        currentPage={getCurrentPage}
        goPrevious={goPrevious}
        goNext={goNext}
        totalPage={totalPage}
        perPage={perPage}
        content={button_group_data}
      />

      {/* Upload files Modal  */}
      {uploadModal && (
        <S.ModalCustom>
          <div className='modal-custom'>
            <Modal
              size='medium'
              close={() => {
                setUploadModal(false)
                closeUploadModal()
              }}
              buttonName={'Add'}
              cancelButtonName={'cancel'}
              func={handleSubmitForm}
              isLoading={isLoading}
              isDisabled={handleValidateForm()}
              infos={{ title: '' }}
              className='upload-modal'
              showActions={false}
            >
              <S.UploadWrapper>
                <>
                  {successMessage && (
                    <S.UploadSuccessContent>
                      <div>
                        <Text family='text' size='md'>
                          {getTableContent(cms_content, 'success_message_files', 'label')}
                        </Text>
                      </div>
                      <div>
                        <S.ButtonGroup>
                          <div style={{ marginRight: '0.5rem' }}>
                            <Button
                              key={'go-to-folder'}
                              kind='warning'
                              id='header_main_button'
                              onClick={(e) =>
                                backToFolder(e, `/my-account/label-library/custom-files/${currentFolder}`)
                              }
                            >
                              {getTableContent(cms_content, 'goto_folder', 'label')}
                            </Button>
                          </div>

                          <Button kind='warning' onClick={() => reuploadAction()}>
                            {getTableContent(cms_content, 're_upload', 'label')}
                          </Button>
                        </S.ButtonGroup>
                      </div>
                    </S.UploadSuccessContent>
                  )}
                </>
                <S.UploadContent>
                  <S.UploadControl>
                    <S.UploadSettings>
                      <S.UploadHeader gradient={true}>{getModalContent('upload-modal', 'title')}</S.UploadHeader>

                      <S.UploadSettingsContainer>
                        <S.UFields>
                          <S.Block>{getInputFieldContent('label-size-width', 'input_label')}</S.Block>
                          <S.InlineInput>
                            <Input
                              dimension='large'
                              type='number'
                              min={100}
                              max={'max'}
                              key={'id'}
                              id={'id'}
                              name={'name'}
                              value={labelWidth}
                              onChange={(e) => setLabelWidth(Number(e.target.value))}
                              label={getInputFieldContent('label-size-width', 'input_label')}
                            />
                            <span className='up-divider'>X</span>
                            <Input
                              dimension='large'
                              type='number'
                              min={'min'}
                              max={'max'}
                              key={'id'}
                              id={'id'}
                              name={'name'}
                              value={labelHeight}
                              onChange={(e) => setLabelHeight(Number(e.target.value))}
                              label={getInputFieldContent('label-size-height', 'input_label')}
                            />
                          </S.InlineInput>
                        </S.UFields>

                        <S.Block>{getTableContent(cms_content, 'save_to_folder', 'label')}</S.Block>
                        <S.InlineInput>
                          <Select2
                            id={getTableContent(cms_content, 'save_to_folder', 'id')}
                            placeholder={getTableContent(cms_content, 'save_to_folder', 'label')}
                            name={getTableContent(cms_content, 'save_to_folder', 'id')}
                            noSelectIco
                            value={selectedFolder === 0 ? currentFolder : selectedFolder}
                            onChange={changeFolder}
                            options={folderSelector}
                          />
                        </S.InlineInput>
                        <S.ButtonGroup>
                          <Button kind='warning' disabled={buffer.length === 0} onClick={() => upload()}>
                            {getModalContent('upload-modal', 'success_action')}
                          </Button>
                          <Button kind='white' onClick={() => setUploadModal(false)}>
                            {getModalContent('upload-modal', 'failure_action')}
                          </Button>
                        </S.ButtonGroup>
                      </S.UploadSettingsContainer>
                    </S.UploadSettings>
                    <S.UploadActionWrapper>
                      <S.UploadHeader>{getModalContent('upload-modal-file', 'title')} </S.UploadHeader>
                      <S.UploadActionWrapperContainer>
                        <S.ButtonGroup>
                          <S.UploadButton>
                            <label htmlFor='file'>
                              <span className='label-upload-button'>
                                <Icon name='upload-set' size='s1' />
                                {getButtonContent('add_file', 'name')}
                              </span>
                              <input
                                type='file'
                                multiple
                                id='file'
                                name='file'
                                accept='application/pdf'
                                onChange={(event) => event.target.files && processUploadFiles(event.target.files)}
                              />
                            </label>
                          </S.UploadButton>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getTableContent(cms_modal_content, 'upload', 'label')
                            }}
                          ></div>
                        </S.ButtonGroup>
                        <S.UploadedIems>
                          {buffer.map((e, index) => {
                            return (
                              currentProgress[index] <= 100 && (
                                <S.UList key={index} isDisabled={currentProgress[index] < 100}>
                                  <a
                                    href='javascript:void(0)'
                                    onClick={() => removeFromBuffer(index)}
                                    className='ul-list-close'
                                  >
                                    <Icon name='close-circle-s' />
                                  </a>
                                  <S.UListItem>
                                    <Icon name='pdf-cco' size='s6' />
                                  </S.UListItem>
                                  <S.UListItem>
                                    <p>{e['file']['name']}</p>
                                  </S.UListItem>
                                  {loadetShowStatus && (
                                    <ProgressLoader
                                      value={currentProgress[index] ? currentProgress[index] : 0}
                                      maxValue={100}
                                      width={40}
                                      height={40}
                                      noText
                                      failed={currentProgress[index] < 0}
                                    />
                                  )}
                                  {currentProgress[index] < 0 && (
                                    <S.ErrorInfo>
                                      <Text size='sm' color='danger'>
                                        {getTableContent(cms_content, 'upload_issue', 'label')}
                                      </Text>
                                      <Button size='small' kind='warning' onClick={() => reUpload(index)}>
                                        {getTableContent(cms_content, 're_upload', 'label')}
                                      </Button>
                                    </S.ErrorInfo>
                                  )}
                                </S.UList>
                              )
                            )
                          })}
                        </S.UploadedIems>
                      </S.UploadActionWrapperContainer>
                    </S.UploadActionWrapper>
                  </S.UploadControl>
                </S.UploadContent>
              </S.UploadWrapper>
            </Modal>
          </div>
        </S.ModalCustom>
      )}
    </S.MultitableWrap>
  )
})

CustomFiles.displayName = 'CustomFiles'
