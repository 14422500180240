import { useReducer, useCallback } from 'react'
import { LabelLibraryContext } from './LabelLibraryContext'
import { LABELLIBRARY_ACTIONS } from './constants'
import { LabelLibraryReducer } from './reducer'
import {
  ds_prices,
  initialSelection,
  basic_fields,
  total,
  basic_fields_response,
  packageType,
  step
} from '@/client/modules/DesignService/data/initialSelection'
export const useValues = () => {
  const [state, dispatch] = useReducer(LabelLibraryReducer, {
    copy_selection: {},
    currentFolder: 'myorder-files',
    currentPage: 1,
    totalPage: 1,
    loadingStatus: false,
    messages: '',
    uploadModal: false,
    changesOnFile: null, // If there is any change on files
    changesOnFolder: null, // If there is any change on folders,
    searchKeyword: '', // To handle searching for table data.
    setCurrentFolderName: '', // To handle searching for table data.
    selectedJobId: '',
    perPage: 0,
    currentSort: []
  })

  /**
   * Adding files to copy selection.
   * @param payload
   * @returns
   */
  const addToCopySelection = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.ADD_TO_COPY_SELECTION,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Set current folder.
   * @param payload
   * @returns
   */
  const setCurrentFolder = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_CURRENT_FOLDER,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Set current page.
   * @param payload
   * @returns
   */
  const setCurrentPage = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_CURRENT_PAGE,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Set total pages.
   * @param payload
   * @returns
   */
  const setTotalPage = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_TOTAL_PAGES,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Set items per each page.
   * @param payload
   * @returns
   */
  const setPerPage = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_PER_PAGE,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Loader.
   * @param payload
   * @returns
   */
  const setLoader = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_LOADER,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Message.
   * @param payload
   * @returns
   */
  const setMessages = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_MESSAGES,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Upload modal.
   * @param payload
   * @returns
   */
  const setUploadModal = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_UPLOAD_MODAL,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Changes on file.
   * @param payload
   * @returns
   */
  const setChangesOnFile = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_CHANGES_ON_FILE,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Changes on folder.
   * @param payload
   * @returns
   */
  const setChangesOnFolder = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_CHANGES_ON_FOLRDERS,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Adds search keyword.
   * @param payload
   * @returns
   */
  const setSearchKeyword = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_SEARCH_KEYWORD,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Set current folder name.
   * @param payload
   * @returns
   */
  const setCurrentFolderName = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_CURRENT_FOLDER_NAME,
        payload: payload
      })
      resolve(true)
    })
  }

  /**
   * Set order number.
   * @param payload
   * @returns
   */
  const setJobId = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_JOB_ID,
        payload: payload
      })
      resolve(true)
    })
  }
  /**
   * Set order number.
   * @param payload
   * @returns
   */
  const setCurrentSort = (payload) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABELLIBRARY_ACTIONS.SET_CURRENT_SORT,
        payload: payload
      })
      resolve(true)
    })
  }

  return {
    copy_selection: state.copy_selection,
    currentFolder: state.currentFolder,
    currentPage: state.currentPage,
    totalPage: state.totalPage,
    loadingStatus: state.loadingStatus,
    messages: state.messages,
    uploadModal: state.uploadModal,
    changesOnFolder: state.changesOnFolder,
    changesOnFile: state.changesOnFile,
    searchKeyword: state.searchKeyword,
    currentFolderName: state.currentFolderName,
    selectedJobId: state.selectedJobId,
    perPage: state.perPage,
    currentSort: state.currentSort,
    addToCopySelection,
    setCurrentFolder,
    setCurrentPage,
    setTotalPage,
    setPerPage,
    setLoader,
    setMessages,
    setUploadModal,
    setChangesOnFolder,
    setChangesOnFile,
    setSearchKeyword,
    setCurrentFolderName,
    setJobId,
    setCurrentSort
  }
}

export const LabelLibraryProvider = ({ children }) => {
  const value = useValues()
  return <LabelLibraryContext.Provider value={value}>{children}</LabelLibraryContext.Provider>
}
