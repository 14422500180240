import styled, { keyframes } from 'styled-components'
import { Button } from '../Button/Button'
import borders from '../theme/borders'
import breakpoint from '../theme/breakpoint'
import color from '../theme/color'
import font from '../theme/font'
import opacity from '../theme/opacity'
import space from '../theme/space'
import zindex from '../theme/zindex'

export const Search = styled.div`
  position: relative;
  z-index: 99;
`
export const SearchInput = styled.div<{ $active: boolean }>`
  display: none;
  position: relative;
  width: 100%;
  height: 35px;
  z-index: ${(p) => (p.$active ? 99 : 0)};

  & .table-search,
  & .basic-input-element {
    height: 100%;
  }

  @media (min-width: ${breakpoint.lg}) {
    display: block;
  }
`

export const SearchIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${space.sm};
  width: ${space.xl2};
  height: ${space.xl2};
`
export const MultiTableSerchWrapper = styled.div`
  width: 50%;
`
export const SearchForm = styled.form``
