// celphone vertical 450px(sm)
// celphone horizontal 600px(md)
// tablet vertical 768px(lg)
// tablet horizontal 992px(xl)
// small laptops 1040px, 1160px, 1280px(xl2, xl3, xl4)
// desktop 1344px(hd)
// large screens 1552px(hd2)
// ultra large desktop display 2208px(ul)

const breakpoint = {
  xxs: '0',
  xs: '18rem',
  xs2: '23rem',
  sm: '28.125rem',
  md: '37.5rem',
  lg: '48rem',
  lg2: '55rem',
  xl: '62rem',
  xl2: '65rem',
  xl3: '72.5rem',
  xl4: '80rem',
  hd: '84rem',
  hd2: '97rem',
  ul: '138rem'
}

export default Object.freeze(breakpoint)
