import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import { Row, Col, Breadcrumb, Checkbox, Button, Link, ProxyImage } from '@/client/components'
import * as S from './styles'
import { Icon } from '@/client/components/Icon'
import { Text } from '@/client/components/Text'
import { MultiTableProps } from './types'
import { useGetFolderEntries, useGetTableData, useGetUserInfos, useRouterPush } from '@/client/hooks'
import { formatDate } from '@/client/utils/formatDate'
import { Controls } from './Controls'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import router from 'next/router'
import { ACTION_BUTTON, BUTTON_GROUP, MAIN_TABLE, TABLE_CONTENT } from '../types'
import { getImageLinkString, getTableContent } from '../helpers'
import { refineValueFromURL } from '@/client/utils/labelLibrary'

const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const GraphicTicketFiles: FC<any> = memo(({ searchItem, content }) => {
  const [eachItem, setEachItem] = useState<string[]>([])
  const [showDropdown, setShowDropdown] = useState(null)
  const { asPath } = useRouterPush()
  const { getCurrentPage, searchParam } = refineValueFromURL(asPath)
  const [eachSortItem, setEachSortItem] = useState<any>([])
  const { data: folderEntries } = useGetFolderEntries(false)
  const [folderSelector, setFolderSelector] = useState([{ id: 1, name: '' }])
  const [activePreview, setActivePreview] = useState<number | null>(null)
  const [subActivePreview, setSubActivePreview] = useState<number | null>(null)

  /**
   * Dynamically accesses CMS.
   * The property names are stored in constants,
   * allowing for dynamic and flexible property access.
   *
   * @param {Object} content - The object containing the cms content data.
   * @param {string} TABLE_CONTENT - Child elements of cms content.
   * @param {string} MAIN_TABLE - Child elements of cms content.
   * @returns {any} cms_content - The content.
   */
  const {
    [TABLE_CONTENT]: { [MAIN_TABLE]: cms_content },
    [BUTTON_GROUP]: { [ACTION_BUTTON]: button_group_data }
  } = content

  /**
   *
   * Context for Label Library
   *
   */
  const {
    addToCopySelection,
    currentFolder,
    totalPage,
    setTotalPage,
    setLoader,
    setMessages,
    searchKeyword,
    setPerPage,
    perPage,
    setCurrentFolderName,
    setJobId,
    setCurrentSort
  } = useContext(LabelLibraryContext)

  const {
    data: tableData,
    isLoading,
    isSuccess: customTableDataReady,
    status: customTableLoadingStatus,
    refetch
  } = useGetTableData({
    filterString: searchParam == null ? `filter=service&page=${getCurrentPage}` : `filter=service&search=${searchParam}`
  })
  const [refinedResults, setRefinedResults] = useState([])

  const selectRow = (elemId, filename, job_id) => {
    setEachItem((prevItems) => [
      ...prevItems.slice(1), // Remove the first item
      tableData['subSorts'].find((e) => e['id'] === elemId) // Add the new item to the end
    ])
    addToCopySelection({
      filename: filename,
      customerFileId: elemId
    })
    setJobId(job_id)
  }

  const selectSubSortsRow = (elem, subSortId) => {
    setEachSortItem((prevItems) =>
      prevItems.map((item) => {
        if (item.id === elem.id) {
          const isPresent = item.subSorts.includes(subSortId)
          const updatedSubSorts = isPresent
            ? item.subSorts.filter((id) => id !== subSortId)
            : [...item.subSorts, subSortId]

          return {
            ...item,
            subSorts: updatedSubSorts
          }
        }
        return item
      })
    )
  }

  // Show preivew for parent elements
  const showPreview = (index: number) => {
    if (activePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setSubActivePreview(null)
      setActivePreview(index)
    }
  }

  // Show preivew for sub elements
  const showSubPreview = (index: number) => {
    if (subActivePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setActivePreview(null)
      setSubActivePreview(index)
    }
  }

  const setFolderContents = () => {
    const folderSelectorMutation = folderEntries && [
      { name: 'Select a folder', id: 0 },
      ...folderEntries.map((e) => {
        return {
          name: e['folder_name'],
          id: e['id']
        }
      })
    ]
    const getCurrentFolder =
      folderEntries && folderEntries.length > 0 && folderEntries.filter((e) => e['id'] === currentFolder)
    console.log(getCurrentFolder, 'sdf')
    if (getCurrentFolder && getCurrentFolder.length > 0) {
      setCurrentFolderName(getCurrentFolder[0]['folder_name'])
    }
    setFolderSelector(folderSelectorMutation)
  }

  useEffect(() => {
    if (tableData && tableData.data.length == 0) {
      setMessages('Sorry, There is no data associated with this folder')
    } else {
      setMessages('')
    }
    if (tableData) {
      setCurrentSort([0])
      setTotalPage(tableData['total'])
      setPerPage(tableData['per_page'])
      setLoader(false)
    }
  }, [tableData, customTableDataReady, customTableLoadingStatus, asPath])

  const goNext = () => {
    const getNextPage = totalPage === getCurrentPage ? getCurrentPage : getCurrentPage + 1
    const refinedPath = asPath.split('?')[0]
    router.push(
      {
        pathname: refinedPath,
        query: { page: getNextPage }
      },
      undefined,
      { shallow: true }
    )
  }

  const goPrevious = () => {
    const previousPage = getCurrentPage === 1 ? getCurrentPage : getCurrentPage - 1
    const refinedPath = asPath.split('?')[0]
    router.push(
      {
        pathname: refinedPath,
        query: { page: previousPage }
      },
      undefined,
      { shallow: true }
    )
  }

  // Handles outside click to hide preview components
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null

      if (activePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
      if (subActivePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
    }

    // Adding event listener to the document
    document.addEventListener('click', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [activePreview, subActivePreview])

  return (
    <S.MultitableWrap>
      <>
        <Row className='multitable-row' middle='xs' isFull>
          <Col xs='12' className='mutile-table-outer-row-header' noGutter>
            <Row isFull className='mutile-table-inner-row mutile-table-inner-row-header' middle='xs'>
              <Col xs='5' xl2='4' noGutter>
                <Row isFull middle='xs'>
                  <Col xs='1' xl2='1' className='multitable-select-field'></Col>
                  <Col xs='2' xl2='3'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent(cms_content, 'preview', 'table_cell_name')}
                    </Text>
                  </Col>
                  <Col xs='9' xl2='8'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent(cms_content, 'filename', 'table_cell_name')}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col xs='2' xl2='2' className='sorts-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'sorts', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' xl2='2' className='size-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'size', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' xl2='2' className='createdate-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'create_date', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' xl2='2' className='lastorder-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'last_order_no', 'table_cell_name')}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
      {tableData?.filteredResults.map((elm, i) => (
        <S.multiTableRow
          key={`table-data-graphic-tickets-${i}`}
          isRowChecked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
        >
          <Row className='multitable-row' middle='xs' isFull>
            <Col xs='12' noGutter>
              <Row isFull className='mutile-table-inner-row' middle='xs'>
                <Col xs='5' xl2='4' noGutter>
                  <Row isFull middle='xs'>
                    <Col xs='1' className='arrow-checkbox'>
                      <Icon name='chevron-down' size='s3' />
                      <Checkbox
                        key={elm['id']}
                        id={elm['id']}
                        value={elm['id']}
                        name={elm['id']}
                        label={''}
                        description={''}
                        checked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
                        onChange={() => selectRow(elm['id'], elm['filename'], elm['job_id'])}
                        kind='circle'
                      />
                    </Col>
                    <Col xs='2' xl2='3' style={{ position: 'relative' }}>
                      <S.ImageBlock>
                        {elm['preview_link'] || elm['preview_link'] !== '' ? (
                          <ProxyImage
                            src={getImageLinkString(`customerFile/${elm['id']}`, '', elm['id'])}
                            page={getCurrentPage}
                            width={127}
                            height={92}
                            alt={'Preview image'}
                            title={'Preview Image'}
                            index={i}
                            showPreview={showPreview}
                            isOpen={activePreview === i}
                          />
                        ) : (
                          <img src='/img/my-account/no-image.png' alt='Preview no image' style={{ maxWidth: '100%' }} />
                        )}
                      </S.ImageBlock>
                    </Col>
                    <Col xs='9' xl2='8'>
                      <S.WrapText>
                        <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                          {elm['filename']}
                        </Text>
                      </S.WrapText>
                    </Col>
                  </Row>
                </Col>

                <Col xs='2' xl2='2' className='sorts-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['number_of_pages']}
                  </Text>
                </Col>
                <Col xs='2' xl2='2' className='size-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['width']} X {elm['height']}
                  </Text>
                </Col>
                <Col xs='2' xl2='2' className='createdate-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {formatDate(elm['created_at'])}
                  </Text>
                </Col>
                <Col xs='2' xl2='2' className='lastorder-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['last_order_number']}
                  </Text>
                </Col>
              </Row>
              {eachItem.length > 0 &&
                eachItem
                  .filter((e) => e['id'] === elm['id'])
                  .map((subSort, subSortIndex) => (
                    <>
                      {Array.from({ length: subSort['subSorts'] }, (_, index) => (
                        <Row isFull middle='xs' className='multiple-select-field-sorts'>
                          <Col xs='5' xl2='4'>
                            <Row isFull middle='xs'>
                              <Col xs='2' noGutter>
                                <Checkbox
                                  key={subSort['id']}
                                  id={subSort['id']}
                                  value={subSort['id']}
                                  name={subSort['id']}
                                  label={''}
                                  description={''}
                                  onChange={() => selectSubSortsRow(elm, subSort['id'])}
                                  kind='circle'
                                  checked={true}
                                />
                              </Col>
                              <Col xs='2' xl2='3' style={{ position: 'relative' }}>
                                <S.ImageBlock>
                                  <ProxyImage
                                    src={getImageLinkString(`customerFile/${elm['id']}`, index + 1, elm['id'])}
                                    page={getCurrentPage}
                                    width={127}
                                    height={92}
                                    alt={'Preview image'}
                                    title={'Preview Image'}
                                    index={index}
                                    showPreview={showSubPreview}
                                    isOpen={subActivePreview === index}
                                  />{' '}
                                </S.ImageBlock>
                              </Col>
                              <Col xs='9' xl2='7'>
                                <S.WrapText>
                                  <Text
                                    family='text'
                                    color='weak'
                                    size='sm'
                                    style={{ margin: '0' }}
                                    title={elm['filename']}
                                  >
                                    {elm['filename']}
                                  </Text>
                                </S.WrapText>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs='2' xl2='2' className='sorts-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {index + 1}/{elm['number_of_pages']}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='size-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['width']} X {elm['height']}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='createdate-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {formatDate(elm['created_at'])}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='lastorder-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['last_order_number']}
                            </Text>
                          </Col>
                        </Row>
                      ))}
                    </>
                  ))}
            </Col>
          </Row>
        </S.multiTableRow>
      ))}
      <Controls
        getCurrentPage={getCurrentPage}
        goPrevious={goPrevious}
        goNext={goNext}
        totalPage={totalPage}
        perPage={perPage}
        content={button_group_data}
      />
    </S.MultitableWrap>
  )
})

GraphicTicketFiles.displayName = 'GraphicTicketFiles'
