import { Auth } from 'aws-amplify'
import { setCookie } from 'nookies'
import { Cookies, ICartCheckoutPayload, ICartPayload, IAddCartItemPayload, ICartSelection } from '@/client/types'
import { api, protectedApi } from '@/client/utils'
import {
  customFiles,
  designServiceFiles,
  graphicTicketFiles,
  myorderFiles,
  folderEntires
} from '@/infra/data/labelLibrary'
import { DESIGN_SERVICE_FILES, GRPAHIC_TICKET_FILES, MY_ORDER_FILES } from '@/client/components/MultiTable/types'
/**
 * Gets folder lists
 * @param cartId
 * @returns
 */
export const getFolderEntries = async () => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/library/${attributes['custom:customer_id']}`
  const { data } = await protectedApi.get<any>(url)
  return data?.customer_project_folders || []
}

/**
 * Gets table data.
 * @param cartId
 * @returns
 */
export const getTabledata = async (filterString = '') => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  if (!attributes['custom:customer_id']) {
    return null
  }

  const url = `/customer/${attributes['custom:customer_id']}/customerFile?${filterString}`
  const { data } = await protectedApi.get<any>(url)
  return data
}
/**
 * Gets table data.
 * @param cartId
 * @returns
 */
export const getCustomTabledata = async (filterString = '') => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  if (!attributes['custom:customer_id']) {
    return null
  }

  const url = `/customer/${attributes['custom:customer_id']}/customerProjectFolder/${filterString}`
  const { data } = await protectedApi.get<any>(url)
  return data
}

/**
 * Gets table data filtered by jobId.
 * @param cartId
 * @returns
 */
export const getJobdata = async (filterString = '') => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  if (!attributes['custom:customer_id']) {
    return null
  }

  const url = `/customer/${attributes['custom:customer_id']}/${filterString}`
  const { data } = await protectedApi.get<any>(url)
  return data
}

// Create folder
export const createFolder = async (payload, customer_id) => {
  const url = `/customer/${customer_id}/customerProjectFolder`
  const { data } = await protectedApi.post<any>(url, payload)
  return data
}
// Delete folder
export const deleteFolder = async (customer_id, id) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${id}`
  const { data } = await protectedApi.delete<any>(url)
  return data
}
// Delete file
export const deleteFile = async (customer_id, folderIdid, fileId, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${folderIdid}/customerFile/${fileId}`
  const { data } = await protectedApi.delete<any>(url)
  return data
}
// Rename folder
export const renameFolder = async (customer_id, id, payload) => {
  const url = `/customer/${customer_id}/customerProjectFolder/${id}`
  const { data } = await protectedApi.patch<any>(url, payload)
  return data
}
// Move folder
export const moveToFolder = async (folderId, fileId, payload) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  if (![MY_ORDER_FILES, GRPAHIC_TICKET_FILES, DESIGN_SERVICE_FILES].includes(folderId)) {
    const url = `/customer/${attributes['custom:customer_id']}/customerProjectFolder/${folderId}/customerFile/${fileId}`
    const { data } = await protectedApi.patch<any>(url, payload)
    return data
  }

  const url = `/customer/${attributes['custom:customer_id']}/customerFile/${fileId}`
  const { data } = await protectedApi.patch<any>(url, payload)
  return data
}

// Download a file
export const moveToDownload = async (folderId, fileId, currentPage) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  if (![MY_ORDER_FILES, GRPAHIC_TICKET_FILES, DESIGN_SERVICE_FILES].includes(folderId)) {
    const url = `/customer/${attributes['custom:customer_id']}/customerProjectFolder/${folderId}/customerFile/${fileId}?preview=0&page=${currentPage}`
    const { data } = await protectedApi.get<any>(url)
    return data
  }
  const url = `/customer/${attributes['custom:customer_id']}/customerFile/${fileId}?preview=0&page=${currentPage}`
  const { data } = await protectedApi.get<any>(url)
  return data
}
// Get Preview of each sorts
export const getPreview = async (filterString) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  // custom folder, URL would be `/customer/${attributes['custom:customer_id']}/customerProjectFolder/${folderIdid}/customerFile/${fileId}?preview=1&page=${currentPage}`
  const url = `/customer/${attributes['custom:customer_id']}/${filterString}`
  const { data } = await protectedApi.get<any>(url, {
    responseType: 'blob'
  })
  return URL.createObjectURL(data)
}
